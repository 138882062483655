<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Detail <small class="text-muted">Polling</small>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <dl>
              <dt>Question: </dt>
              <dd>{{ data.question }}</dd>
              <dt>Status: </dt>
              <dd>{{ data.is_expired ? 'Active' : 'Expired' }}</dd>
              <dt>Date: </dt>
              <dd>{{ data.date }}</dd>
            </dl>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <h5>Participant</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(description)="dataDesc">
                  {{ dataDesc.item.description | dotConcat(100) }}
                </template>
              </b-table>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardBody, BCardHeader, BCardTitle, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'polling_value', label: 'Value' },
      ],
      active_role: null,
      data: {
        answer: [],
      },
    }
  },
  computed: {
    corporate() {
      return this.$store.state.corporate.corporate
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      const polling = this.data.answer
      // this.totalRows = polling.length

      return polling
    },
  },
  created() {
    this.checkAuth()
    this.getDataPolling()
    this.getCorporate()
    this.active_role = localStorage.getItem('role')
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCorporate() {
      this.$store.dispatch('corporate/loadCorporate')
    },
    getDataPolling() {
      const cid = localStorage.getItem('client_id')
      const { id } = this.$route.params
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      // this.$store.dispatch('banner/loadBanner')
      this.$axios.get(`${cid}/member/corporate/polling/${id}`, config).then(response => {
        this.data = response.data.data
      })
    },
    submit() {
      const { id } = this.$route.params
      const cid = localStorage.getItem('client_id')
      const input = this.data
      const data = new FormData()

      data.append('title', input.title)
      data.append('image', input.file.image)
      data.append('description', input.description)
      data.append('link', input.link)
      data.append('corporate', input.corporate)

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }

      this.$axios.post(`${cid}/banner/${id}`, data, config)
        .then(() => {
          // Success code
          this.$bvToast.toast('Success', {
            title: 'Edit Banner',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
            autoHideDelay: 3000,
          })

          setTimeout(() => {
            this.$router.push({ path: '/banner' })
          }, 3000)
        })
        .catch(error => {
          console.log(error)
        })
    },

    showModalCrop() {
      this.modal_crop_image = true
    },
    resetImage() {
      this.$refs['file-input'].reset()

      this.data.file.image = null
      this.data.file.url = null
      this.data.file_raw = null
      this.data.image = null
    },
    setCropArea() {
      this.$refs.cropper.setCropBoxData({
        width: 512,
        height: 512,
      })
    },
    resetCropImage() {
      if (!this.data.file_raw) {
        this.$refs['file-input'].reset()
      }
    },
    handleCropImage() {
      this.data.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.data.image = this.data.file.url

      const block = this.data.file.url.split(';')
      const contentType = block[0].split(':')[1]
      const realData = block[1].split(',')[1]
      this.data.file.image = this.b64toBlob(realData, contentType)
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
